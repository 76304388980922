/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 1, 2020 */



@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-bold.woff2') format('woff2'),
         url('sf-mono-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-bolditalic.woff2') format('woff2'),
         url('sf-mono-bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-heavy.woff2') format('woff2'),
         url('sf-mono-heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-heavyitalic.woff2') format('woff2'),
         url('sf-mono-heavyitalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-light.woff2') format('woff2'),
         url('sf-mono-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-lightitalic.woff2') format('woff2'),
         url('sf-mono-lightitalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-medium.woff2') format('woff2'),
         url('sf-mono-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-mediumitalic.woff2') format('woff2'),
         url('sf-mono-mediumitalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-regular.woff2') format('woff2'),
         url('sf-mono-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-regularitalic.woff2') format('woff2'),
         url('sf-mono-regularitalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-semibold.woff2') format('woff2'),
         url('sf-mono-semibold.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'SF Mono';
    src: url('sf-mono-semibolditalic.woff2') format('woff2'),
         url('sf-mono-semibolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}